@import "../../../styles/responsive.scss";

.header {
  background-color: var(--cl-background-primary);
  min-height: 245px;
  position: relative;
  background-image: url(../../../images/bg_header.png);
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;

  padding-top: 20px;
}

.nav {
  max-width: 2000px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.routes {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: 80px;
  padding: 0;
}

.link {
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.15s linear;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: var(--cl-background-primary);
  &:hover {
    opacity: 0.7;
  }
}

.logo {
  width: 250px;
}

@media (min-width: 1920px) {
  .header {
    background-size: 100% 80%;
  }
}

@include responsive(fullscreen) {
  .routes {
    gap: 55px;
  }

  .link {
    &_name {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .logo {
    width: 190px;
  }
}

@include responsive(screen) {
  .header {
    min-height: 174px;
  }
  .routes {
    gap: 42px;
  }

  .link {
    &_name {
      font-size: 9px;
      line-height: 14px;
    }
  }

  .logo {
    width: 129px;
  }
}

@include responsive(tablet) {
  .header {
    min-height: 130px;
  }
  .routes {
    gap: 32px;
  }

  .link {
    &_name {
      font-size: 7px;
      line-height: 12px;
    }
  }
}

@include responsive(phone) {
  .nav {
    padding-right: 25px;
    padding-left: 25px;
  }
  .routes {
    min-height: 98px;
    display: none;
  }
  .logo {
    width: 115px;
  }
}
