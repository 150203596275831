@import "../../styles/responsive.scss";

.container {
  &__intro {
    background: var(--cl-background-primary);
  }

  &__benefits {
    background: var(--cl-black);
    position: relative;

    @include responsive(phone) {
      background: linear-gradient(
        var(--cl-black),
        var(--cl-background-primary)
      );
    }
  }

  &__integration {
    background-image: url("../../images//homepage/integration/bg.svg");
    background-color: var(--cl-background-primary);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

//SECTION ONE

.intro {
  position: relative;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding-left: 135px;
  padding-right: 117px;
  padding-bottom: 119px;
  min-height: 907px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__title {
    z-index: 8;
    //white-space: nowrap;
    position: relative;
    color: var(--cl-white);
    text-transform: uppercase;
  }

  &__subtitle {
    color: var(--cl-white);
    max-width: 607px;
    text-align: left;
    z-index: 5;
  }

  &__button {
    margin-left: 100px;
    width: max-content;
  }

  &__text {
    max-width: 507px;
    text-align: left;
    font-weight: 500;
    margin-top: 13px;
    margin-bottom: 87px;
    z-index: 5;
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;

    &_2 {
      text-align: right;
    }
    &_title {
      color: var(--cl-white);
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__bg {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &_birds {
      position: absolute;
      bottom: 32px;
      left: 0;
    }
    &_tree {
      position: absolute;
      z-index: 1;
      left: 53%;
      transform: translateX(-50%);
      bottom: -320px;

    }

    &_waves {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 961px;
    }
  }
}

.benefit {
  position: absolute;
  z-index: 15;

  &__title {
    color: var(--cl-white);
  }

  &__text {
    margin-top: 4px;
  }

  &_1 {
    left: 261px;
    top: 133px;
  }
  &_2 {
    left: 416px;
    top: 316px;
  }
  &_3 {
    top: 123px;
    right: 313px;
  }
  &_4 {
    top: 233px;
    right: 525px;
  }
}

@include responsive(fullscreen) {
  .intro {
    padding-left: 96px;
    padding-right: 96px;
    padding-bottom: 84px;
    min-height: 646px;
    &__bg {
      width: 436px;
      &_birds {
        width: 240px;
      }
      &_tree {
        bottom: -220px;
        width: 337px;
      }

      &_waves {
        width: 684px;
      }
    }

    &__subtitle {
      max-width: 436px;
    }
    &__text {
      margin-top: 10px;
      margin-bottom: 62px;
      max-width: 360px;
    }
    &__button {
      margin-left: 70px;
    }

    &__columns {
      &_title {
        margin-bottom: 10px;
      }
    }
  }

  .benefit {
    &__text {
      margin-top: 3px;
    }

    &_1 {
      top: 79px;
    }
    &_2 {
      top: 187px;
    }
    &_3 {
      top: 71px;
    }
    &_4 {
      top: 150px;
      right: 410px;
    }
  }
}

@include responsive(screen) {
  .intro {
    padding-left: 72px;
    padding-right: 72px;
    padding-bottom: 63px;
    min-height: 500px;
    &__bg {
      width: 328px;
      &_birds {
        width: 180px;
      }
      &_tree {
        bottom: -165px;
        width: 254px;
      }

      &_waves {
        width: 512px;
      }
    }

    &__subtitle {
      max-width: 327px;
    }
    &__text {
      margin-top: 8px;
      margin-bottom: 50px;
      max-width: 270px;
    }
    &__button {
      margin-left: 53px;
    }

    &__columns {
      &_title {
        margin-bottom: 10px;
      }
    }
  }

  .benefit {
    &__text {
      margin-top: 3px;
    }

    &_1 {
      top: 59px;
      left: 140px;
    }
    &_2 {
      top: 135px;
      left: 221px;
    }
    &_3 {
      top: 53px;
      right: 87px;
    }
    &_4 {
      top: 113px;
      right: 208px;
    }
  }
}

@include responsive(tablet) {
  .intro {
    padding-left: 54px;
    padding-right: 54px;
    padding-bottom: 47px;
    min-height: 370px;
    &__bg {
      width: 246px;
      &_birds {
        width: 135px;
      }
      &_tree {
        bottom: -120px;
        width: 190px;
      }

      &_waves {
        width: 385px;
      }
    }

    &__subtitle {
      max-width: 245px;
    }

    &__text {
      margin-top: 4px;
      margin-bottom: 25px;
      max-width: 203px;
    }
    &__button {
      margin-left: 40px;
    }

    &__columns {
      &_title {
        margin-bottom: 7px;
      }
    }
  }

  .benefit {
    &__text {
      margin-top: 2px;
    }

    &_1 {
      top: 45px;
      left: 106px;
    }
    &_2 {
      top: 96px;
      left: 166px;
    }
    &_3 {
      top: 40px;
      right: 52px;
    }
    &_4 {
      top: 84px;
      right: 148px;
    }
  }
}

@include responsive(phone) {
  .intro {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    min-height: 100%;
    background: linear-gradient(var(--cl-background-primary), var(--cl-black));

    &__bg {
      top: 240px;
      width: 304px;
      &_birds {
        display: none;
      }
      &_tree {
        top: 400px;
        width: 266px;
      }

      &_waves {
        display: none;
      }
    }

    &__subtitle {
      max-width: 266px;
      text-align: center;
      margin: 0 auto;
      margin-top: 40px;
    }

    &__text {
      margin-top: 8px;
      margin-bottom: 28px;
      text-align: center;
      max-width: 335px;
    }
    &__button {
      margin: 0 auto;
    }

    &__columns {
      margin-top: 430px;
      flex-direction: column-reverse;
      justify-content: center;
      &_title {
        text-align: center;
        margin-bottom: 12px;
      }
    }
  }

  .benefit {
    &__title {
      line-height: 24px;
    }
    &__text {
      margin-top: 2px;
      max-width: 130px;
      line-height: 1;
    }

    &_1 {
      top: 230px;
      left: 12px;
    }
    &_2 {
      top: 374px;
      right: 19px;
      left: auto;
    }
    &_3 {
      top: 367px;
      left: 60px;
      right: auto;
    }
    &_4 {
      top: 40px;
      right: 23px;
    }
  }
}

@media (max-width: 370px) {
  .intro {
    &__title {
      font-size: 17px;
    }

    &__bg {
      top: 260px;
      min-width: 304px;

      &_tree {
        top: 420px;
      }
    }
  }
  .benefit {
    &_1 {
      top: 260px;
      left: 12px;
    }
    &_2 {
      top: 411px;
      right: 19px;
      left: auto;
    }
    &_3 {
      top: 410px;
      left: 50px;
      right: auto;
    }
    &_4 {
      top: 55px;
      right: 12px;
    }
  }
}

// SECTION TWO ---------------------------------------------------

.benefits {
  max-width: max-content;
  width: 100%;
  margin: 0 auto;
  padding: 100px 0;
  text-align: left;
  position: relative;

  &__wrap {
    position: absolute;
    top: 32%;
    left: 0;
    z-index: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  &__block {
    max-width: 680px;
    width: 100%;
    text-align: right;
    color: var(--cl-white);
  }
  &__title {
    color: var(--cl-white);
    text-align: left;
    max-width: 570px;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 700;
    font-family: var(--font-family-secondary);
    margin-bottom: 12px;
    letter-spacing: 0.1em;
    color: var(--cl-primary);
    text-transform: uppercase;
    display: block;
  }

  &__list {
    margin-top: 80px;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(4, 330px);
    gap: 32px;
  }
}

@include responsive(fullscreen) {
  .benefits {
    padding: 69px 0;

    &__block {
      max-width: 483px;
    }

    &__subtitle {
      font-size: 8px;
      margin-bottom: 7px;
    }
    &__title {
      max-width: 404px;
    }

    &__list {
      margin-top: 56px;
      grid-template-columns: repeat(4, 233px);
      gap: 22px;
    }
    &__wrap {
      top: 36%;
      width: 219px;
    }
  }
}
@include responsive(screen) {
  .benefits {
    padding: 52px 0;

    &__block {
      max-width: 382px;
    }

    &__subtitle {
      font-size: 6px;
      margin-bottom: 6px;
    }
    &__title {
      max-width: 303px;
    }

    &__list {
      margin-top: 42px;
      grid-template-columns: repeat(4, 175px);
      gap: 17px;
    }

    &__wrap {
      top: 36%;
      width: 176px;
    }
  }
}
@include responsive(tablet) {
  .benefits {
    padding: 52px 0;

    &__block {
      max-width: 272px;
      // font-size: 7px;
      // padding: 6px 11px;
    }

    &__subtitle {
      font-size: 5px;
      margin-bottom: 4px;
    }
    &__title {
      max-width: 227px;
    }

    &__list {
      margin-top: 32px;
      grid-template-columns: repeat(4, 131px);
      gap: 12px;
    }
    &__wrap {
      top: 34%;
      width: 122px;
    }
  }
}
@include responsive(phone) {
  .benefits {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__subtitle {
      display: none;
    }

    &__block {
      display: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 2;
    }
    &__title {
      text-align: center;
      max-width: 261px;
      margin-bottom: 49px;
      font-size: 20px;
    }

    &__list {
      margin-top: 0;
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: minmax(auto, 280px);
      gap: 52px;
    }

    &__wrap {
      top: -8%;
      width: 92px;
    }
  }
}

// SECTION THREE

.integration {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0 144px;
  padding-right: 259px;
  padding-left: 65px;
  gap: 50px;

  &__box {
    display: block;
    padding: 39px 60px;
    padding-top: 0;
  }

  &__subtitle {
    text-align: left;
    color: var(--cl-white);
    white-space: nowrap;
    font-size: 32px;
    line-height: 50px;
    margin-top: 11px;
  }

  &__column {
    &_2 {
      position: relative;
    }
  }

  &__title {
    color: var(--cl-white);
    text-align: center;
    margin-bottom: 20px;
  }

  &__list {
    display: grid;
    list-style: none;
    grid-template-columns: repeat(5, 120px);
    gap: 15px;
  }

  &__service {
    border-radius: 4px;
  }

  &__img {
    height: auto;
    display: block;
  }

  &__icon {
    display: block;
    width: 100%;
  }
  &__item {
    &_0 {
      position: absolute;
      top: 118px;
    }
    &_2 {
      position: absolute;
      right: 72px;
      bottom: -6px;
    }
    &_1 {
      position: absolute;
      top: 243px;
      right: -124px;
    }
  }
}

@include responsive(fullscreen) {
  .integration {
    padding: 50px 0 102px;
    padding-right: 128px;
    padding-left: 46px;
    gap: 36px;

    &__box {
      padding: 27px 42px;
    }

    &__img {
      width: 582px;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__subtitle {
      font-size: 22px;
      line-height: 35px;
      margin-top: 7px;
    }

    &__list {
      grid-template-columns: repeat(5, 86px);
      gap: 12px;
    }
    &__item {
      &_1 {
        top: 200px;
        right: -100px;
      }
    }
  }
}

@include responsive(screen) {
  .integration {
    padding: 40px 0 76px;
    padding-right: 93px;
    padding-left: 34px;
    gap: 27px;

    &__img {
      width: 436px;
    }

    &__box {
      padding: 20px 32px;
    }

    &__title {
      margin-bottom: 11px;
    }

    &__subtitle {
      font-size: 17px;
      line-height: 26px;
      margin-top: 5px;
    }

    &__list {
      grid-template-columns: repeat(5, 65px);
      gap: 9px;
    }

    &__item {
      &_0 {
        top: 70px;
        left: 20px;
      }
      &_1 {
        top: 160px;
        right: -75px;
      }
    }
  }
}

@include responsive(tablet) {
  .integration {
    padding: 29px 0 57px;
    padding-right: 71px;
    padding-left: 26px;
    gap: 20px;

    &__img {
      width: 328px;
    }

    &__box {
      padding: 15px 27px;
    }

    &__title {
      margin-bottom: 8px;
    }

    &__subtitle {
      font-size: 13px;
      line-height: 20px;
      margin-top: 4px;
    }

    &__list {
      grid-template-columns: repeat(5, 48px);
      gap: 7px;
    }
    &__item {
      &_0 {
        top: 60px;
        left: 15px;
      }
      &_2 {
        right: 42px;
      }
      &_1 {
        top: 130px;
        right: -55px;
      }
    }
  }
}

@include responsive(phone) {
  .integration {
    flex-direction: column;
    padding: 25px 17px 75px;
    gap: 44px;

    &__img {
      width: 243px;
    }

    &__box {
      padding: 0;
    }

    &__title {
      font-size: 23px;
      line-height: 27px;
      margin-bottom: 44px;
    }

    &__subtitle {
      display: none;
    }

    &__list {
      grid-template-columns: repeat(5, 57px);
    }

    &__item {
      &_0 {
        top: 20px;
        left: -29px;
      }
      &_2 {
        bottom: -20px;
        right: -45px;
      }
      &_1 {
        top: 37px;
        right: -55px;
      }
    }
  }
}

@media (max-width: 390px) {
  .integration {
    &__list {
      grid-template-columns: repeat(3, 57px);
      justify-content: center;
    }

    &__item {
      &_0 {
        top: 20px;
        left: -6px;
      }
      &_2 {
        bottom: -20px;
        right: 0;
      }
      &_1 {
        top: 37px;
        right: -15px;
      }
    }
  }
}
// SECTION FOUR

.features {
  background: var(--cl-black);
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  &__title {
    color: var(--cl-white);
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    width: 100%;
    padding: 25px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--cl-background-primary);
    &.yellow {
      background: var(--cl-primary);
    }
  }
}

@include responsive(fullscreen) {
  .features {
    &__item {
      height: 248px;
    }
    &__title {
      top: -56px;
    }
  }
}

@include responsive(screen) {
  .features {
    &__item {
      height: 186px;
    }
    &__title {
      top: -43px;
    }
  }
}

@include responsive(tablet) {
  .features {
    &__item {
      height: 139px;
    }
    &__title {
      top: -43px;
    }
  }
}

@include responsive(phone) {
  .features {
    &__title {
      white-space: nowrap;
    }
    &__list {
      display: flex;
      flex-direction: column;
    }
    &__item {
      max-width: none;
      height: auto;
    }
  }
  .intro {
    &__columns {
      &_2 {
        margin-top: 20px;
      }
    }
  }
}
