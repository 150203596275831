@import "../../styles/responsive.scss";

.benefit {
  background: var(--cl-primary);
  border-radius: 10px;
  padding: 54px 32px 32px;
  max-width: 330px;
  position: relative;
  height: 252px;
  z-index: 2;
}
.index {
  font: var(--font-m);
  font-family: var(--font-family-secondary);
  font-weight: bold;
  position: absolute;
  background: linear-gradient(-45deg, #835613 0%, #d1ac33 100%);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  top: -40px;
  left: 36px;
}
.text {
  margin-top: 8px;
  color: var(--cl-black);
  max-height: 100px;
  overflow: hidden;
  font-size: 17px;
}

.title {
  font-size: 19px;
}

@include responsive(fullscreen) {
  .benefit {
    padding: 38px 22px 22px;
    max-width: 233px;
    max-height: 200px;
    height:100%;
    min-height: 179px;
  }

  .text {
    font-size: 12px;
    line-height: 18px;
  }

  .title {
    font-size: 13px;
  }

  .index {
    font-size: 25px;
    font-weight: 700;
    width: 56px;
    height: 56px;
    top: -33px;
    left: 20px;
  }
}

@include responsive(screen) {
  .benefit {
    padding: 28px 17px 16px;
    max-width: 175px;
    min-height: 134px;
  }

  .text {
    font-size: 9px;
  }

  .title {
    font-size: 10px;
  }

  .index {
    font: var(--font-s);
    font-weight: 700;
    width: 42px;
    height: 42px;
    top: -22px;
    left: 20px;
  }
}

@include responsive(tablet) {
  .benefit {
    padding: 22px 12px 22px;
    max-width: 131px;
    min-height: 100px;
  }

  .text {
    font-size: 7px;
    line-height: 10px;
  }

  .title {
    font-size: 8px;
  }

  .index {
    font: var(--font-xxs);
    font-weight: 700;
    width: 32px;
    height: 32px;
    top: -16px;
    left: 17px;
  }
}

@include responsive(phone) {
  .benefit {
    padding: 32px 13px 32px;
    max-width: 100%;
    min-height: 180px;
  }

  .text {
    font-size: 16px;
    line-height: 17px;
    text-align: center;
  }

  .title {
    font-size: 16px;
    text-align: center;
  }

  .index {
    font-size: 24px;
    font-weight: 700;
    width: 54px;
    height: 54px;
    top: -28px;
    left: calc(50% - 26px);
    
  }
}
