.popup {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  inset: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  &_opened {
    visibility: visible;
    opacity: 1;
  }
  &__container {
    position: relative;
    max-width: 530px;
    color: var(--cl-white);
    background-color: #333333;
    background-image: url(../../images/Africa_map.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 25px 40px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 95vh;
  }
}

.title {
  font-size: 18px;
  margin: 0;
  font-weight: 400;
  color: var(--cl-grey);
}

.popupForm {
  max-width: 524px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 25px;
  padding: 0 10px;
  overflow-y: auto;

  overflow-x: hidden;
  scroll-behavior: smooth;

  &::-webkit-scrollbar-thumb {
    height: 10px;
    background: #8d8d8d;
    border-radius: 50px;
    width: 3px;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;
  }
  &_text {
    color: var(--cl-white);
    margin: 0;
    margin-bottom: 6px;
    text-align: left;
    font-size: 12px;
  }

  &_span {
    color: #d00000;
    margin: 0;
  }

  &_input {

    border: none;

  }
  &_input,
  &_message {
    backdrop-filter: blur(7px);
    outline: none;
    width: 100%;
    font-size: 14px;
    color: var(--cl-white);
    cursor: pointer;
    box-sizing: border-box;
    background-color: rgba(51, 51, 51, 0.8);
    padding: 10px 25px;
    box-shadow: 0 0 0 0.7px var(--cl-white);
    border-radius: 5px;
    &::placeholder {
      color: rgba(163, 163, 163, 63.92%);
    }

    &:focus {
      box-shadow: 0 0 0 1px var(--cl-white);
    }

    &.error {
      box-shadow: 0 0 0 0.7px #b40000;
    }
  }

  &_message {
    height: 130px;
    resize: none;
    box-sizing: border-box;
    cursor: pointer;
  
  }
}

.errorText {
  color: #b40000;
  font-size: 12px;
  margin: 0;
  margin-top: 2px;
  text-align: left;
  display: block;
}

.button {
  cursor: pointer;
  background-color: var(--cl-primary);
  max-width: 524px;
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
  border: none;
  color: var(--cl-black);
  line-height: 20px;
  font-weight: 600;
  font-size: 12px;
  transition: all 0.15s linear;
  margin-top: 10px;

  &:hover:not(:disabled) {
    opacity: 0.7;
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--cl-placeholder);
    color: var(--cl-white);
    opacity: 0.8;
  }
}

@media screen and (max-width: 726px) {
  .popup {
    &__container {
      max-width: 445px;
      box-sizing: border-box;
      padding: 20px;
      margin: 10px;
    }
  }

  .popupForm {
    margin-top: 20px;
    gap: 10px;
    padding: 0 2px;

    &_input,
    &_message {
      padding: 12px 15px;
    }
  }
}
