@import '../../styles/responsive.scss';

.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
}

.input {
  width: 160px;
  background: var(--cl-background-primary);
  color: var(--cl-white);
  display: flex;
  outline: none;
  border: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 0;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 600;
}

.list {
  width: 250px;
  position: absolute;
  width: 100%;
  top: 55px;
  background: var(--cl-background-primary);
  color: var(--cl-white);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 20px 15px;
  //border: 0.01rem solid var(--cl-white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;

  transition: all 0.15s linear;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border: 1px solid var(--cl-white);
  border-radius: 10px;
  padding: 8px 0;
  cursor: pointer;
  transition: opacity 0.15s linear;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
}

.arrow {
  width: 12px;
  height: 7px;
  transition: transform 0.15s linear;
  &.open {
    transform: rotateX(180deg);
  }
}

.icon {
  width: 25px;
}

@include responsive(fullscreen) { 
  .input {
    width: 114px;
    gap: 7px;
    height: 30px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 7px;
    padding: 5px 0;
  }
  .list {
    width: 200px;
    padding: 14px 10px;
    top: 35px;
    border-radius: 7px;
  }
  .item {
    padding: 5px 0;
    font-size: 12px;
    line-height: 18px;
    border-radius: 5px;
  }

  .icon {
    width: 17px;
  }

  .arrow {
    width: 9px;
    height: 5px;
  }
}

@include responsive(screen) { 

  .input {
    width: 85px;
    gap: 5px;
    height: 22px;
    font-size: 9px;
    line-height: 14px;
    border-radius: 5px;
    padding: 4px 0;
  }
  .list {
    padding: 10px 8px;
    top: 25px;
    border-radius: 5px;
    width: 154px;
  }
  .item {
    padding: 4px 0;
    font-size: 9px;
    line-height: 14px;
    border-radius: 5px;
  }

  .icon {
    width: 14px;
  }

  .arrow {
    width: 6px;
    height: 4px;
  }
}

@include responsive(phone) {
  .input {
    width: 100px;
    height: 30px;
    padding: 9.5px 0;
  }
  .list {
    padding: 10px 8px;
    top: 35px;
    border-radius: 5px;
  }
  .item {
    padding: 4px 0;
    font-size: 9px;
    line-height: 14px;
    border-radius: 5px;
  }

  .icon {
    width: 14px;
  }

  .arrow {
    width: 6px;
    height: 4px;
  }
}
