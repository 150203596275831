@import "../../../styles/responsive.scss";

.button {
  outline: none;
  background-color: var(--cl-primary);
  color: var(--cl-white);
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 68px;
  height: 65px;
  cursor: pointer;
  transition: opacity 0.15s linear;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.black {
  color: var(--cl-black);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@include responsive(fullscreen) {
  .button {
    height: 46px;
    padding: 0px 48px;
    font-size: 14px;
    line-height: 24px;
  }
}

@include responsive(screen) {
  .button {
    height: 34px;
    padding: 0px 36px;
    border-radius: 6px;
    font-size: 11px;
    line-height: 21px;
  }
}

@include responsive(tablet) {
  .button {
    padding: 0 27px;
    height: 27px;
    font-size: 8px;
    border-radius: 5px;
  }
}

@include responsive(phone) {
  .button {
    padding: 0 43px;
    height: 42px;
    font-size: 13px;
    line-height: 1;
    border-radius: 5px;
  }
}

