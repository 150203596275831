@import "../../../styles/responsive.scss";

.container {
  background-image: url(../../../images/footer/bg_footer.svg);
  background-repeat: no-repeat;
  background-color: var(--cl-background-primary);
  background-size: cover;
  padding: 15px 0;
}

.wrapper {
  padding: 0 40px;
}

.footer {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 2000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  gap: 7px;
}

.line {
  overflow: hidden;
  white-space: nowrap;
  color: var(--cl-white);
  font-size: 22px;
  font-weight: 400;
  line-height: 1;
  background: #262626;
  height: 46px;
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;

  &__logo {
    margin-right: 6px;
    width: 1.5em;
  }
}

.marquee {
  display: inline-flex;
  align-items: center;
  padding-left: 100%;
  animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s infinite linear;
  -moz-animation: marquee 30s infinite linear;
}

.line:hover .marquee {
  animation-play-state: paused;
}

.nav {
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.5;
  padding-top: 15px;
  align-items: center;

  &__link {
    color: var(--cl-black);
    transition: opacity 0.15s linear;
    &:hover {
      opacity: 0.7;
    }
  }
}

.logo {
  width: 250px;
}

.text {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: var(--cl-white);
  padding: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  max-width: 1920px;
  margin: 0 auto;
}

.copyright {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: var(--cl-black);

  &__img {
    display: block;
    width: 80px;
  }
}

.social {
  display: flex;
  gap: 13px;

  &__item {
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    transition: opacity 0.15s linear;
    &:hover {
      opacity: 0.7;
    }
  }
}

@include responsive(fullscreen) {
  .logo {
    width: 190px;
  }
}

@include responsive(screen) {
  .text {
    font-size: 10px;
    padding: 15px;
  }

  .line {
    font-size: 16px;
  }

  .social {
    gap: 10px;

    &__item {
      width: 40px;
      height: 40px;
    }
  }

  .logo {
    width: 129px;
  }
}

@include responsive(tablet) {
  .text {
    font-size: 7px;
    padding: 10px;
  }

  .line {
    font-size: 12px;
    height: 33px;
    margin: 10px 0;
  }

  .nav {
    font-size: 12px;
  }

  .copyright {
    font-size: 12px;

    &__img {
      width: 60px;
    }
  }
  .social {
    gap: 10px;

    &__item {
      width: 30px;
      height: 30px;
    }
  }
}

@include responsive(phone) {
  .text {
    font-size: 11px;
    padding: 5px;
  }

  .wrapper {
    padding: 0 5px;
  }
  .line {
    margin: 15px 0;
  }
  .footer {
    padding: 0 20px;
    flex-direction: column-reverse;

    &__line {
      display: none;
    }
  }

  .nav {
    flex-direction: column;
    margin-bottom: 25px;
    font-size: 14px;
  }

  .copyright {
    margin-bottom: 20px;
    font-size: 14px;
    flex-direction: column-reverse;
  }

  .logo {
    width: 115px;
  }
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

@-moz-keyframes marquee {
  0% {
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -moz-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
