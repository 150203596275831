@import "../../styles/responsive.scss";

.container {
  background-color: #000000;
  position: relative;
}
.contact {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 68px 0;
  display: flex;
  justify-content: center;
}


.info {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.birds {
  position: absolute;
  right: 140px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

}

.component {
  display: flex;
padding: 0 60px;
box-sizing: border-box;
  align-items: center;
  width: 100%;
  gap: 20px;

  &_text {
    width: max-content;
    text-align: left;
    color: #ffffff;
    font-weight: 700;
    font-size: 44px;
    line-height: 50px;
    margin: 0;
    margin-bottom: 10px;
  }

  &_item {
    margin: 0;
    color: #c9c9c9;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: left;
  }
}

.button {
  margin-left: 141px;
  margin-right: 131px;
  outline: none;
  background-color: #ff9f1c;
  color: #000000;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 80px;
  padding: auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: opacity 0.15s linear;

  &:hover {
    opacity: 0.7;
  }
}


.contacts {
  color: var(--cl-white);
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  &__link {
    transition: opacity .15s linear;
    color: var(--cl-white);
    &:hover {
      opacity: .8;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 22px;
    line-height: 1.2;
  }

  &__text {
    max-width: 260px;
    width: 100%;
    margin-left: 15px;
    text-align: left;
    font-weight: 400;
    color: var(--cl-white);
 
  }

  &__img {
    width: 2em;
    height: 2em;
  }
}

@include responsive(fullscreen) {
  .contact {
    padding: 48px 0;
  }

  .contacts {
    &__item{
      font-size: 14px;
      line-height: 18px;
    }

    &__text {
      max-width: 155px;
    }
  }

  .birds {
    width: 214px;
  }

  .component {
    &_text {
      //width: 347px;
      font-size: 31px;
      line-height: 35px;
      margin-bottom: 6px;
    }

    &_item {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .button {
    margin-left: 121px;
    margin-right: 93px;
    font-size: 17px;
    line-height: 18px;
    width: 142px;
    height: 56px;
  }
}

@include responsive(screen) {
  .contact {
    padding: 36px 0;
  }



  .birds {
    width: 162px;
    right: 74px;
  }

  .component {
    &_text {
      //width: 260px;
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 6px;
    }

    &_item {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .button {
    margin-left: 75px;
    margin-right: 69px;
    font-size: 12px;
    line-height: 13px;
    width: 106px;
    height: 42px;
  }
}

@include responsive(tablet) {
  .contact {
    padding: 27px 0;
  }

  .birds {
    width: 120px;

    right: 60px;
  }
  .contact {
    padding-right: 53px;
  }

    
  .contacts {
    &__item{
      font-size: 10px;
      line-height: 1.2;
    }

    &__text {
      max-width: 120px;
    }
  }

  .component {
    padding: 0 40px;
    &_text {
     // width: 195px;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 3px;
    }

    &_item {
      font-size: 8px;
      line-height: 10px;
    }
  }

  .button {
    margin-left: 50px;
    margin-right: 21px;
  }
}

@include responsive(phone) {
  .contact {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 76px;
    background: linear-gradient(var(--cl-background-primary), var(--cl-black));
  }

  .contacts {
    &__text {
      font-size: 16px;
      max-width: none;
    }
  }

  .info {
    flex-direction: column;
  }

  .birds {
    display: none;
  }
  .component {
    flex-direction: column-reverse;
    align-items: center;
    gap: 60px;
    padding: 0 20px;
    &_text {
      width: 326px;
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 0;
      text-align: center;
      margin: 0 auto;
      line-height: 1.2;
      padding: 0 17px;
    }

    &_item {
      font-size: 16px;
      margin: 0 auto;
      line-height: 18px;
      margin-bottom: 45px;
      margin-top: 15px;
      max-width: 236px;
      text-align: center;
    }
  }

  .button {
    margin-left: 0;
    margin-right: 0;
    font-size: 17px;
    line-height: 18px;
    width: 180px;
    height: 57px;
  }

  .container {
    background: transparent;
  }
}
