@import "../../styles/responsive.scss";

.card {
    background: var(--cl-background-primary);
    display: block;
    text-align: left;
    width: max-content;
    &.yellow{
        background: var(--cl-primary);
        & > .title, .text { 
            color: var(--cl-background-primary);
        }
    }
}

.title {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    color: var(--cl-primary);
    margin-bottom: 13px;
    margin-top: 7px;
    white-space: nowrap;
}

.text {
    font: var(--font-s);
    color: var(--cl-primary);
    max-width: 360px;
}


@include responsive(fullscreen) {
    .title {
        font-size: 21px;
        line-height: 1.2;
        margin-bottom: 9px;
        margin-top: 2px;
    }
    .text {
        font-size: 13px;
        line-height: 18px;
        max-width: 267px;
    }
    .icon {
        width: 35px;  
        &__big {
            width: 92px;
        } 
    }
}

@include responsive(screen) {
    .title {
        font-size: 16px;
        margin-bottom: 6px;
    }
    .text {
        font-size: 10px;
        line-height: 15px;
        max-width: 192px;
    }
    .icon {
        width: 28px;  
        &__big {
            width: 69px;
        }  
    }
}

@include responsive(tablet) {
    .title {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .text {
        font-size: 7px;
        line-height: 12px;
        max-width: 144px;
    }

    .icon {
        width: 20px;  
        &__big {
            width: 52px;
        }  
    }
}

@include responsive(phone) {
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .title {
        font-size: 17px;
        text-align: center;
        margin-bottom: 1px;
    }
    .text {
        font-size: 16px;
        line-height: 1.2;
        text-align: center;
        max-width: 297px;
    }

    .icon {
        width: 28px;  
        &__big {
            width: 69px;
        }  
    }
}