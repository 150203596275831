:root {
    // Шрифты
    --font-family-main: "Monserat", sans-serif;
    --font-family-secondary: "Inter", sans-serif;

    --font-size-xxs:14px;
    --font-line-xxs: 18px;
    --font-xxs: var(--font-size-xxs) / var(--font-line-xxs) var(--font-family-main);
    --font-size-xs:16px;
    --font-line-xs: 26px;
    --font-xs: var(--font-size-xs) / var(--font-line-xs) var(--font-family-main);
    --font-size-s:18px;
    --font-line-s: 28px;
    --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);
    --font-size-m:36px;
    --font-line-m: 44px;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);
    --font-size-l:40px;
    --font-line-l: 48px;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);
    --font-size-xl: 44px;
    --font-line-xl: 50px;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);
    --font-size-xxl: 48px;
    --font-line-xxl: 58px;
    --font-xxl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);


    // z-index
    --modal-z-index: 10;

    // Цвета
    --cl-white: #fff;
    --cl-black: #000;
    --cl-primary:#FF9F1C;
    --cl-secondary:#A3A3A3;
    --cl-background-primary: #262626;
    --cl-placeholder: #c5c5c5;
    --cl-grey:#C9C9C9;

}
