@import 'responsive.scss';

html {
  scroll-behavior: smooth;
}

section {
  scroll-margin: 20px;
}

h1 {
  font: var(--font-xxl);
  font-weight: 900;
}

h2 {
  font: var(--font-xl);
  font-weight: bold;
}

h3 {
  font: var(--font-l);
  font-weight: bold;
}

h4 {
  font: var(--font-m);
  font-weight: bold;
}

h5 {
  font: var(--font-s);
  font-weight: bold;
}

p {
  font: var(--font-xs);
  font-weight: 400;
  color: var(--cl-secondary);
}

@include responsive(fullscreen) { 
  h1 {
    font-size: 34px;
    line-height: 44px;
  }

  h2 {
    font-size: 31px;
    line-height: 41px;
  }

  h3 {
    font-size: 28px;
    line-height: 38px;
  }

  h4 {
    font-size: 25px;
    line-height: 35px;
  }

  h5 {
    font-size: 13.5px;
    line-height: 20px;
  }

  p {
    font-size: 12px;
    line-height: 18px;
  }
}

@include responsive(screen) { 
  h1 {
    font-size: 26px;
    line-height: 36px;
  }
  h2 {
    font-size: 24px;
    line-height: 34px;
  }
  h3 {
    font-size: 22px;
    line-height: 32px;
  }

  h4 {
    font-size: 19px;
    line-height: 29px;
  }
  h5 {
    font-size: 10px;
    line-height: 16px;
  }

  p {
    font-size: 9px;
    line-height: 14px;
  }
}

@include responsive(tablet) { 
  h1 {
    font-size: 20px;
    line-height: 30px;
  }
  h2 {
    font-size: 18px;
    line-height: 20px;
  }
  h3 {
    font-size: 16px;
    line-height: 24px;
  }

  h4 {
    font-size: 14px;
    line-height: 20px;
  }
  h5 {
    font-size: 8px;
    line-height: 12px;
  }

  p {
    font-size: 6.5px;
    line-height: 10px;
  }
}

@include responsive(phone) {
  h1 {
    font-size: 19px;
    line-height: 29px;
  }
  h2 {
    font-size: 21px;
    line-height: 31px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
  }

  h4 {
    font-size: 20px;
    line-height: 30px;
  }
  h5 {
    font-size: 16px;
    line-height: 19px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}

