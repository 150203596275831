@import "../../styles/responsive.scss";

.integration {
  border-radius: 24px;
  border: 0.5px solid var(--cl-black);
  background: linear-gradient(-45deg, #835613 0%, #d1ac33 100%);
  padding: 16px 24px;
  display: flex;
  gap: 16px;
  width: max-content;
  align-items: center;
}

.text {
  color: var(--cl-black);
  font-size: 20px;
  line-height: 28px;
  max-width: min-content;
  text-align: left;
  font-weight: bold;
  font-family: var(--font-family-secondary);
}

.icon {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  &.bg {
    background: var(--cl-black);
  }
}

.img {
  width: 32px;
  &__big {
    width: 65px;
  }
}

@include responsive(fullscreen) {
  .integration {
    border-radius: 17px;
    padding: 11px 17px;
    gap: 11px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
  }
  .icon {
    width: 45px;
    height: 45px;
    border-radius: 14px;
  }

  .img {
    width: 20px;
    &__big {
      width: 46px;
    }
  }
}

@include responsive(screen) {
  .integration {
    border-radius: 12px;
    padding: 8px 13px;
    gap: 8px;
  }

  .text {
    font-size: 10px;
    line-height: 15px;
  }
  .icon {
    width: 34px;
    height: 34px;
    border-radius: 10px;
  }

  .img {
    width: 20px;
    &__big {
      width: 34px;
    }
  }
}

@include responsive(tablet) {
  .integration {
    border-radius: 10px;
    padding: 6px 9px;
    gap: 6px;
  }

  .text {
    font-size: 8px;
    line-height: 11px;
  }
  .icon {
    width: 26px;
    height: 26px;
    border-radius: 8px;
  }

  .img {
    width: 14px;
    &__big {
      width: 26px;
    }
  }
}

@include responsive(phone) {
  .integration {
    border-radius: 10px;
    padding: 8px 9px;
    gap: 6px;
  }

  .text {
    font-size: 16px;
    line-height: 1.2;
  }
  .icon {
    width: 32px;
    height: 32px;
    border-radius: 8px;
  }

  .img {
    width: 16px;
    &__big {
      width: 32px;
    }
  }
}

@media (max-width: 390px) {

 .text{
  font-size: 10px;
  line-height: 14px;
}
}